.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*Sidebar/Dashboard styling*/
.sidebar-vertical {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 75px;
  text-align: center;
}
.wrapper {
  display: grid;
  grid-template-columns: 0% 100%;
  min-height: 200px;
}
.content {
  align-self: start;
}

.sidebar-vertical {
  display: flex;
  flex-direction: column;
}
.all-space {
  width: 100%;
  height: 100%
}


/*Login*/
.login-container {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.container-login {
  width: 300px;
}
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.register-form {
  width: 300px;
}

/*Cards */
.card-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.custom-card {
  width: 240px;
  height: 200px;
  margin: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-text {
  font-size: 14px;
}

.card-container a {
  text-decoration: none;
  color: inherit;
}

.edit-button {
  margin-right: 10px;
  border: none;
}

.delete-button {
  margin-left: 10px;
  border: none;
}
.custom-card:hover {
  cursor: pointer;
}


/* Cards inside of tabs */
.listings-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.listings-custom-card {
  width: calc(25% - 32px);
  height: 225px;
  margin: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.listings-custom-card:hover {
  cursor: pointer;
}

.current-listings-header {
  display: flex;
  margin-top: 20px;
}

.button-container {
  margin-right: 8px;
  margin-bottom: 8px;
}
